//Componentes generales
import React, { useReducer, useContext, useEffect } from "react";
import {
  addGroup,
  deleteGroup,
  getAllGroup,
  updateGroup,
} from "../../api/group";

import gruposReducer from "./gruposReducer";
import gruposContext from "./gruposContext";

//Context authUser
import authContext from "../../context/authentication/authContex";

//Context usuarios
import usuariosContext from "../../context/usuarios/usuariosContext";

//Context control
import controlContext from "../../context/control/controlContext";

//Socket
import { SocketContext } from "../socket/socket";

//Types
import {
  ACTUALIZAR_LIST_GROUP,
  MODIFICAR_GROUP,
  AGREGAR_GROUP,
  ELIMINAR_GROUP,
} from "./gruposActions";
import { sendNotificationPushByUserIdGroup } from "../../api/user";

/* 
    State encargado de la gestión de usuarios.
*/
const GruposState = (props) => {
  //Socket
  const server = useContext(SocketContext);

  //Context auth
  const aContext = useContext(authContext);
  const { usuario, usuarioAutenticado } = aContext;

  //Context control requerimientos
  const cContext = useContext(controlContext);
  const { filtrosRango, modificarFiltrosRango } = cContext;

  //Context usuarios
  const uContext = useContext(usuariosContext);
  const { actualizarUserList } = uContext;

  const initialState = {
    gruposList: [],
  };

  const [state, dispatch] = useReducer(gruposReducer, initialState);

  const actualizarGroupList = async () => {
    try {
      const resp = await getAllGroup();
      const grupos = resp.data.data;

      dispatch({
        type: ACTUALIZAR_LIST_GROUP,
        payload: grupos,
      });

      return grupos;
    } catch (err) {
      console.log(err);
    }
  };

  const agregarGroup = async (grupo, usuariosId) => {
    try {
      const { name, cantUser } = grupo;
      const resp = await addGroup({ name, cantUser }, usuariosId);
      const grupos = resp.data.data;

      return grupos;
    } catch (err) {
      console.log(err);
    }
  };

  const modificarGroup = async (id, updates) => {
    try {
      console.log(id);
      console.log(updates);
      const resp = await updateGroup(id, updates);
    } catch (err) {
      console.log(err);
    }
  };

  const eliminarGroup = async (id) => {
    try {
      console.log(id);
      const resp = await deleteGroup(id);
    } catch (err) {
      console.log(err);
    }
  };

  const enviarNotificacionPush = async (groupId, title, message) => {
    try {
      const resp = await sendNotificationPushByUserIdGroup(
        groupId,
        title,
        message
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (server) {
      server.on("addGroup", (resp) => {
        console.log("NUEVO GRUPO GUARDADO");
        if (!usuario || usuario == null) {
          usuarioAutenticado();
        }
        actualizarUserList();
        actualizarGroupList();
      });

      server.on("modGroup", (resp) => {
        console.log("GRUPO MODIFICADO");
        actualizarUserList();
        let gruposVUsuario = [];
        if (!usuario || usuario == null) {
          usuarioAutenticado();
        }
        if (usuario) {
          usuario.gruposV.map((grupoId) => {
            gruposVUsuario.push(grupoId);
          });
        }
        if (gruposVUsuario.includes(resp.data._id)) {
          modificarFiltrosRango(usuario._id, filtrosRango);
        }
      });
      server.on("deleteGroup", (resp) => {
        console.log("GRUPO ELIMINADO");
        if (!usuario || usuario == null) {
          usuarioAutenticado();
        }
        actualizarUserList();
        actualizarGroupList();
      });

      return () => server.off(["addGroup", "modGroup", "deleteGroup"]);
    }
  }, [server]);

  return (
    <gruposContext.Provider
      value={{
        gruposList: state.gruposList,
        actualizarGroupList,
        agregarGroup,
        modificarGroup,
        eliminarGroup,
        enviarNotificacionPush,
      }}
    >
      {props.children}
    </gruposContext.Provider>
  );
};

export default GruposState;