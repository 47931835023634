//Roles
import { ADMIN, REQUERIMIENTO, MODIFICAR_REQ, CONTROL_NPI, CONTROL_OC, CONTROL_POP} from './roles'

//Habilitados para ver solapa nav de productos.
export const VER_PRODUCTOS = [ADMIN, CONTROL_NPI, CONTROL_OC, CONTROL_POP];
//Habilitados para ver todas las notas de pedido de la lista y no solo las que fueron creados por el usuario.
export const VER_TODOS_NOTAS_PEDIDO = [ADMIN, CONTROL_NPI];
//Habilitados para ver todas las órdenes de compra de la lista y no solo las que fueron creadas por el usuario.
export const VER_TODOS_ORDENES_COMPRA = [ADMIN, CONTROL_OC];
//Habilitados para ver todos los preliminares de órden de pago de la lista y no solo las que fueron creadas por el usuario.
export const VER_TODOS_PRELIMINARES_ORDEN_PAGO = [ADMIN, CONTROL_POP];
//Habilitados para modificar el estado de autorización de una nota de pedido.
export const MODIFICAR_ESTADO_NOTA_PEDIDO = [ADMIN, CONTROL_NPI];
//Habilitados para modificar el estado de autorización de una órden de compra.
export const MODIFICAR_ESTADO_ORDEN_COMPRA = [ADMIN, CONTROL_OC];
//Habilitados para modificar el estado de autorización de un preliminar de órden de pago.
export const MODIFICAR_ESTADO_PRELIMINAR_ORDEN_PAGO = [ADMIN, CONTROL_POP];
//Habilitados para editar notas privadas de una nota de pedido ya creada.
export const EDITAR_NOTAS_PRIVADAS_NPI = [ADMIN, CONTROL_NPI];
//Habilitados para editar notas privadas de una órden de compra ya creada.
export const EDITAR_NOTAS_PRIVADAS_OC = [ADMIN, CONTROL_OC];
//Habilitados para editar notas privadas de un preliminar de órden de pago ya creado.
export const EDITAR_NOTAS_PRIVADAS_POP = [ADMIN, CONTROL_POP];
//Habilitados para editar notas publicas de una nota de pedido ya creada.
export const EDITAR_NOTAS_PUBLICAS_NPI = [ADMIN, CONTROL_NPI];
//Habilitados para editar notas publicas de una órden de compra ya creada.
export const EDITAR_NOTAS_PUBLICAS_OC = [ADMIN, CONTROL_OC];
//Habilitados para editar notas publicas de un preliminar de órden de pago ya creado.
export const EDITAR_NOTAS_PUBLICAS_POP = [ADMIN, CONTROL_POP];
//Habilitados para usar la pantalla de edición de un requerimiento ya creado.
export const EDITAR_REQUERIMIENTO = [ADMIN, MODIFICAR_REQ];
//Habilitados para ver solapa nav de usuario.
export const VER_USUARIOS = [ADMIN];
//Habilitados para ver solapa nav de solicitantes.
export const VER_SOLICITANTES = [ADMIN, CONTROL_NPI, CONTROL_OC, CONTROL_POP];
//Habilitados para ver solapa nav de responsables.
export const VER_RESPONSABLES = [ADMIN, CONTROL_NPI, CONTROL_OC, CONTROL_POP];
//Habilitados para ver solapa nav de sector de requerimientos.
export const VER_SECTORES_REQUERIMIENTOS = [ADMIN, CONTROL_NPI, CONTROL_OC, CONTROL_POP];
//Habilitados para ver solapa nav de sector de depósitos.
export const VER_DEPOSITOS = [ADMIN, CONTROL_NPI, CONTROL_OC, CONTROL_POP];
//Habilitados para reenviar una nota de pedido/requerimiento desde la lista de notas de pedido.
export const REENVIO_NPI_MOSCUZZA = [ADMIN, CONTROL_NPI];
//Habilitados para reenviar una órden de compra desde la lista de órdenes de compra.
export const REENVIO_OC_MOSCUZZA = [ADMIN, CONTROL_OC];
//Habilitados para reenviar un preliminar de órden de pago desde la lista de preliminares de pago.
export const REENVIO_POP_MOSCUZZA = [ADMIN, CONTROL_POP];

export const VER_GRUPOS = [ADMIN];
