//Componentes generales
import React, { useReducer, useContext, useEffect } from "react";
import usuariosReducer from "./usuariosReducer";
import usuariosContext from "./usuariosContext";

//Context auth
import authContext from "../../context/authentication/authContex";

//Axios
import clienteAxios from "../../config/axios";

//Socket
import { SocketContext } from "../socket/socket";

//Rutas de api
import {
  getAllUsers,
  updateUser,
  addUser,
  deleteUser,
  verifyQUsers,
  getUsersInGroupV,
} from "../../api/user";

//Acciones
import {
  ACTUALIZAR_LIST_USER,
  VER_FORM_USER,
  VER_USER,
  SELECCIONAR_USER,
  ESCUCHA_MODIFICAR_USER,
  MODIFICAR_USER,
  AGREGAR_USER,
  ELIMINAR_USER,
  ERROR,
  LIMPIAR_ERROR,
  ACTUALIZAR_LIST_USER_VIEW,
} from "./usuariosActions";

/* 
    State encargado de la gestión de usuarios.
*/
const UsuariosState = (props) => {
  const server = useContext(SocketContext);

  const initialState = {
    userList: [],
    userListView: [],
    userSeleccionado: null,
    verModificar: false,
    verUser: false,
    error: null,
  };

    //Context auth
    const aContext = useContext(authContext);
    const { usuario, usuarioAutenticado } = aContext;

  useEffect(() => {
    if (server) {
      //Usuario Creado
      server.on("userCreated", (resp) => {
        actualizarUserList();
        console.log(
          "Se creó el usuario: ",
          resp.userName,
          ". Se actualizó el userList"
        );
      });

      return () => server.off("userCreated");
    }
  }, [server]);

  useEffect(() => {
    if (server) {
      //Usuario Modificado
      server.on("updateUser", (resp) => {
        if(!usuario){
            usuarioAutenticado()
        }
        if(usuario && resp && resp._id === usuario._id){
            usuarioAutenticado()
            console.log("Se modificó el usuario en sesión: ", resp.userName);
        }
        actualizarUserList();
        console.log(
          "Se modificó el usuario: ",
          resp.userName,
          ". Se actualizó el userList"
        );
      });

      return () => server.off("updateUser");
    }
  }, [server]);
  
  useEffect(() => {
    if (server) {
      //Usuario Eliminado
      server.on("deleteUser", (resp) => {
        actualizarUserList();
        console.log(
          "Se eliminó el usuario: ",
          resp.userName,
          ". Se actualizó el  userList"
        );
      });

      return () => server.off("deleteUser");
    }
  }, [server]);

  const [state, dispatch] = useReducer(usuariosReducer, initialState);

  /* 
        Función encargada de actualizar la lista de usuarios.
    */
  const actualizarUserList = async () => {
    const resp = await getAllUsers(); //clienteAxios.get(`/user`);

    // Analizar si es mejor devolver un arreglo con objeto de usuario en vez de un objeto con el grupo y usuario.

    const usuarios = resp.data.data;

    dispatch({
      type: ACTUALIZAR_LIST_USER,
      payload: usuarios,
    });

    return usuarios;
  };

  //Funcion que actualiza la lista de usuarios
  const actualizarUserListView = async (usuario) => {
    const respUsuarios = await getUsersInGroupV(usuario._id);
    const usuarios = respUsuarios.data.data.map((u) => u.user);
    const usuariosSinDuplicados = [
      ...new Set(usuarios.map((u) => u.userName)),
    ].map((userName) => usuarios.find((u) => u.userName === userName));
    console.log(usuariosSinDuplicados);
    if (!usuariosSinDuplicados.some((u) => u.userName === usuario.userName))
      usuariosSinDuplicados.push(usuario);
    dispatch({
      type: ACTUALIZAR_LIST_USER_VIEW,
      payload: usuariosSinDuplicados,
    });
    return usuariosSinDuplicados;
  };

  /* 
        Función encargada de hacer visible el formulario de Modificación.
    */
  const verFormModificar = async () => {
    dispatch({
      type: VER_FORM_USER,
    });
  };

  /* 
        Función encargada de hacer visible el detalle de usuario.
    */
  const verUserF = async () => {
    dispatch({
      type: VER_USER,
    });
  };

  /* 
        Función encargada de marcar como seleccionado un usuario del listado.
            Donde user es el usuario seleccionado.
    */
  const seleccionarUser = async (user) => {
    dispatch({
      type: SELECCIONAR_USER,
      payload: user,
    });
  };

  /* 
        Función que modifica un usuario.
            Donde user es el usuario modificado.
    */
  const modificarUser = async (user) => {
    if (user.password === "") delete user.password;
    try {
      if (state.userSeleccionado !== null) {
        await updateUser(user); //clienteAxios.put(`/user`, { id, updates: user});
        await verFormModificar();
      }
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: error.response.data.errMsg,
      });
    }
  };

  /* 
        Función encargada de agregar un nuevo usuario.
            Donde user es el usuario a agregar.
                  byUser es el usuario en sesión.
    */
  const agregarUser = async (user, byUser) => {
    if (
      state.userSeleccionado === null ||
      state.userSeleccionado === undefined
    ) {
      try {
        await addUser(user, byUser); //clienteAxios.post(`/user`, {user: {...user, userNameA: byUser.userName, userAID: byUser._id}});
        await verFormModificar();
      } catch (error) {
        dispatch({
          type: ERROR,
          payload: error.response.data.errMsg,
        });
      }
    }
  };

  /* 
        Función que elimina un usuario.
            Donde user es el usuario a eliminar
    */
  const eliminarUser = async (user) => {
    try {
      const resp = await deleteUser(user);
      if (!resp || resp.status !== 200) {
        throw new Error("No se pudo eliminar el usuario");
      }
      return resp;
    } catch (error) {
      throw error;
    }
  };

  const limpiarError = async () => {
    dispatch({
      type: LIMPIAR_ERROR,
    });
  };

  /* 
        Función que verifica la licencia del cliente. Devuelve la cantidad de usuarios habilitados que tiene y la cantidad que su licencia permite.
    */
  const verificarLicencia = async () => {
    const respuesta = await verifyQUsers(); //clienteAxios.get('/user/enabled/quantity');
    return respuesta.data;
  };

  return (
    <usuariosContext.Provider
      value={{
        userList: state.userList,
        userSeleccionado: state.userSeleccionado,
        verModificar: state.verModificar,
        verUser: state.verUser,
        error: state.error,
        actualizarUserList,
        verFormModificar,
        seleccionarUser,
        modificarUser,
        agregarUser,
        eliminarUser,
        verUserF,
        limpiarError,
        verificarLicencia,
        userListView: state.userListView,
        actualizarUserListView,
      }}
    >
      {props.children}
    </usuariosContext.Provider>
  );
};

export default UsuariosState;