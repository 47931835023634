//Componentes generales
import React, { useState, useContext, useEffect } from "react";
import { FixedSizeList } from "react-window";

//Componentes generales
import SearchInput from "../../components/searchInput/SearchInput";
import StateChange from "../../components/StateChange/StateChange";
import ReqInfo from "../ReqInfo/ReqInfo";
import FilterScreen from "../filterScreen/FilterScreen";
import AddFilter from "../addFilter/AddFilter";
import FormReq from "../FormReq/FormReq";
import RenderRow from "../renderRows/RRReqs";
import windowSizeContext from "../../context/windowSize/windowSizeContext";

//Estilos
import "./ReqList.css";

//Fecha y hora formateadas
import { getNewFormattedDate } from "../../utils/formatDate";

//Context
import controlContext from "../../context/control/controlContext";
import authContext from "../../context/authentication/authContex";

/* 
    Componente encargado de mostrar el listado de requerimientos.
        Donde currentUser es el usuario en sesión.
              listTDoc es la lista de requerimientos.
              modificarEstado es la función del context encargarda de realizar una modificación de un campo especifico.
              verDetalle es la función que recupera el detalle de un requerimiento.
              filtros es el filtro donde se aplican las modificaciones por el usuario.
              aplicarFiltros es la función del context que aplica los filtros.
              generaPDF es la función del context que genera y descarga un archivo pdf.
              checkPermisos es la función del context que chequea si el usuario en sesión tiene permisos.
              guardarComo es la función del context encargada de guardar un requerimiento generado por un "repetir".
              editar es la función del context encargada de guardar un requerimiento generado por un "editar".
              eventoClickEdicion en la función del context que recupera los datos de un requerimiento a editar.
              recuperaFilesDetalle es la función del context que recupera los archivos de un requerimiento.
              sendToAPI es la función del context que envia la solicitud de enviar el requerimiento a Softland.
*/
function ReqList({
  currentUser,
  listTDoc,
  userListView,
  reqsSectorsList,
  applicantsList,
  responsiblesList,
  modificarEstado,
  verDetalle,
  filtros,
  aplicarFiltros,
  generaPDF,
  checkPermisos,
  guardarComo,
  editar,
  eventoClickEdicion,
  recuperaFilesDetalle,
  sendToAPI,
}) {
  /*
    Se obtiene el dato "comprobante-apl" del localStorage y el dato "tipo-comprobante" del sessionStorage
  */
  const comprobanteApl = JSON.parse(localStorage.getItem("comprobante-apl"));
  const tipoComprobante = JSON.parse(
    sessionStorage.getItem("tipo-comprobante")
  );

  let todos = 0;
  let pendientes = 0;
  let autorizados = 0;

  if (comprobanteApl) {
    todos = 1;
  } else if (!comprobanteApl) {
    pendientes = 1;
  }

  //state para marcar el requerimiento seleccionado.
  const [selected, setSelected] = useState("");
  //state para mostrar dialog de Edición.
  const [selectedEdit, setSelectedEdit] = useState("");
  //state para mostrar dialog de cambio de Estado.
  const [selectedState, setSelectedState] = useState("");
  //state para mostrar dialog de filtro.
  const [selectedFilter, setSelectedFilter] = useState("");
  //state para mostrar dialog de formulario requerimiento (guardar como).
  const [selectedRepeat, setSelectedRepeat] = useState("");
  //state que indica que tabla hija renderizar.
  const [comprobanteSeleccionado, setComprobanteSeleccionado] = useState(
    tipoComprobante == "NPI" || comprobanteApl ? "NPI" : "OC"
  );
  /*state para deshabilitar las solapas de Órdenes de Compra/Requerimientos hasta que termine de cargar el 
  componente con los comprobantes */
  const [disabledElements, setDisabledElements] = useState(true);
  //state para saber si se modificaron los filtros
  const [filtrosModificados, setFiltrosModificados] = useState(false);
  //state para filtros.
  const [filter, setFilter] = useState("");

  //state de workspaces seleccionado (Ya definidos: Pendientes y Autorizados).
  const [activeWorkSpace, setActiveWorkSpace] = useState({
    pendientes: pendientes,
    autorizados: autorizados,
    todos: todos,
  });

  //state para definir estados no enviables.
  const [stateNoEnviables, setStatesNoEnviable] = useState([
    "PENDIENTE",
    "PENDIENTE (REVISIÓN MOSCUZZA)",
    "RECHAZADO",
  ]);

  //state para definir tamaño para lista.
  const [itemSize, setItemSize] = useState(330);

  //Context control requerimientos
  const cContext = useContext(controlContext);
  const { iniciarFiltros } = cContext;

  //Context auth
  const aContext = useContext(authContext);
  const { usuario } = aContext;

  // Calcula el valor del height según el tamaño de la pantalla y el tamaño del navbar
  const wSContext = useContext(windowSizeContext);
  let { height } = wSContext;

  useEffect(() => {
    if (comprobanteApl) {
      setFilter(comprobanteApl);

      // Se remueve el ítem del local storage después de usarlo
      localStorage.removeItem("comprobante-apl");
    }

    handlerIniciarlizarFiltros(activeWorkSpace, comprobanteSeleccionado);
  }, []);

  const handlerIniciarlizarFiltros = async (
    activeWorkSpace,
    comprobanteSeleccionado
  ) => {
    setDisabledElements(true);
    await iniciarFiltros(activeWorkSpace, usuario, comprobanteSeleccionado);
    setTimeout(() => {
      setDisabledElements(false);
    }, 1000);
  };

  /* 
    Manejador que setea el  filtro de busqueda.
    Donde data es lo ingresado por el usuario.
  */
  const handdlerSearchInput = (data) => {
    setFilter(data);
  };

  /* 
    Manejador que abre/cierra dialog de información del requerimiento seleccionado.
    Donde item es el requerimiento seleccionado.
    En este punto, se recuperan los archivos adjuntados al requerimiento.
    */
  const handlerDetails = async (item) => {
    setSelected(null);
    if (item) {
      const files = await recuperaFilesDetalle(item, "adjunto");
      const comprobantePdf = await recuperaFilesDetalle(
        item,
        "comprobante-pdf"
      );
      setSelected({ item, files, comprobantePdf });
    }
  };

  /* 
    Función para dividir el título del Sector de Requerimientos
  */

  const separarTitulo = (title) => {
    const reqSectorForTitle = title.indexOf("]");
    let newReqSectorForTitle = title.slice(0, reqSectorForTitle + 2).trim();
    let titleWithoutReqSector = title.slice(reqSectorForTitle + 2).trim();
    newReqSectorForTitle = newReqSectorForTitle.replace(/^\[|\]$/g, "");
    return { titleWithoutReqSector, newReqSectorForTitle };
  };

  /* 
    Función encargada de mostrar el dialog de formulario de requerimiento.
    Donde item es el requerimiento del que se hace un guardar como.
    prods es la lista de productos del requerimiento.
    agregaItemF es la función de agrega itm (No esta en uso).
    Se setea el estado del requerimiento como pendieente y se agrega al titulo el prefijo "Copia" junto al numero del requerimiento repetido.
  */

  const repeat = async (item, prods, agregaItemF) => {
    setSelectedRepeat(null);
    if (item) {
      const { tDocCodFor, tDocNroFor, _id, title, ...rest } = item;
      const nuevaListaP = prods.map((p) => {
        const { _id, ...rest } = p;
        return rest;
      });

      const { titleWithoutReqSector, newReqSectorForTitle } = separarTitulo(
        item.title
      );

      setSelectedRepeat({
        item: {
          ...rest,
          title: `Copia ${tDocCodFor}-${tDocNroFor} ${titleWithoutReqSector}`,
          state: "PENDIENTE",
          enviado: false,
          enviadoState: false,
          fecAlt: getNewFormattedDate(),
          fecAut: null,
          fecMod: getNewFormattedDate(),
          notePriv: "",
          notePubl: "",
          observ: "",
          observAut: "",
          apl: [],
        },
        nuevaListaP,
        newReqSectorForTitle,
        agregaItemF,
      });
    }
  };

  /* 
    Manejador que abre/cierra el dialog de formulario en modo guardar como.
    Donde item es el requerimiento.
  */
  const handlerRepeat = async (item) => {
    setSelectedRepeat(null);
    if (item) {
      setSelectedRepeat(item);
      guardarComo(item);
    }
  };

  /* 
    Manejador que abre/cierra el dialog de formulario en modo edición.
    Donde req es el requerimiento a editar.
    agregaItemF es la función de agrega itm (No esta en uso).
    En este punto se recuperan los items y archivos.
  */

  const handlerEdit = async (req, agregaItemF) => {
    setSelectedEdit(null);
    if (req) {
      const { items, files } = await eventoClickEdicion(req);
      const { ...rest } = req;
      const nuevaListaP = items.map((p) => {
        const { ...rest } = p;
        return rest;
      });

      const { titleWithoutReqSector, newReqSectorForTitle } = separarTitulo(
        rest.title
      );

      setSelectedEdit({
        req: { ...rest, title: titleWithoutReqSector },
        newReqSectorForTitle,
        nuevaListaP,
        agregaItemF,
        files,
      });
    }
  };

  /* 
    Manejador que abre/cierra el dialog de cambio de estado.
    Donde item es el requerimiento.
  */
  const handlerState = async (item) => {
    setSelectedState(item);
  };

  /* 
    Manejador que abre/cierra el fialog de filtros.
    Donde itm es un booleano que determina si se ve o no.
  */
  const handlerClickFilter = (itm) => {
    setSelectedFilter(itm);
  };

  /* 
    Manejador encargado de abrir en una nueva ventana el archivo seleccionado.
  */
  const handlerAbrirURL = (url) => {
    window.open(url);
  };

  const handleWorkSpace = async (statusToDisplay) => {
    setFilter("")
    let workspaceToSet =
      statusToDisplay == "todos"
        ? { todos: 1, autorizados: 0, pendientes: 0 }
        : statusToDisplay == "autorizados"
        ? { todos: 0, autorizados: 1, pendientes: 0 }
        : { todos: 0, autorizados: 0, pendientes: 1 };
    let estado =
      statusToDisplay == "todos"
        ? [
            "PENDIENTE",
            "PENDIENTE (REVISIÓN MOSCUZZA)",
            "RECHAZADO",
            "AUTORIZADO",
          ]
        : statusToDisplay == "pendientes"
        ? ["PENDIENTE", "PENDIENTE (REVISIÓN MOSCUZZA)"]
        : ["AUTORIZADO"];
    setActiveWorkSpace(workspaceToSet);
    if (filtrosModificados === true) {
      await handlerIniciarlizarFiltros(workspaceToSet, comprobanteSeleccionado);
      setFiltrosModificados(false);
    } else {
      const filterEstado = { ...filtros.filtrosEstado, state: estado };
      const filterRango = filtros.filtrosRango;
      aplicarFiltros({ filterRango, filterEstado }, "");
    }
  };

  /* 
    Manejador que setea los filtros de estado marcandolo solo con los tipos de comprobante que el 
    usuario seleccione ("Órdenes de Compra o Requerimientos").
  */
  const handleChangeComprobante = async (comprobante) => {
    setFilter("")
    setComprobanteSeleccionado(comprobante);
    const activeWorkSpace = {
      pendientes: 1,
      autorizados: 0,
      todos: 0,
    }
    setActiveWorkSpace(activeWorkSpace)
    let codFor = [];
    let state = [];
    if (comprobante == "OC") {
      codFor = ["OC", "OCP"];
      state = ["PENDIENTE", "PENDIENTE (REVISIÓN MOSCUZZA)"];
    } else if (comprobante == "NPI") {
      codFor = ["REQ", "NPI", "NPIP"];
      state = ["PENDIENTE"];
    } else if (comprobante == "POP") {
      codFor = ["POP"];
      state = ["PENDIENTE"];
    }
    if (filtrosModificados === true) {
      await handlerIniciarlizarFiltros(activeWorkSpace, comprobante);
      setFiltrosModificados(false);
    } else {
      const filterEstado = {
        ...filtros.filtrosEstado,
        state: state,
        codFor: codFor,
      };
      const filterRango = filtros.filtrosRango;
      aplicarFiltros({ filterRango, filterEstado }, "");
    }
  };

  return (
    <div>
      <div className="navbar-reqList">
        <div
          className={`${
            activeWorkSpace.pendientes
              ? "navbar-container-reqList-active"
              : "navbar-container-reqList"
          } ${disabledElements ? "navbar-container-disabled" : ""}`}
          onClick={() =>
            !disabledElements ? handleWorkSpace("pendientes") : null
          }
        >
          <div className="navbar-item-reqList">
            <div className="navbar-item-label-top-xx">PENDIENTES</div>
          </div>
        </div>
        <div
          className={`${
            activeWorkSpace.autorizados
              ? "navbar-container-reqList-active"
              : "navbar-container-reqList"
          } ${disabledElements ? "navbar-container-disabled" : ""}`}
          onClick={() =>
            !disabledElements ? handleWorkSpace("autorizados") : null
          }
        >
          <div className="navbar-item-reqList">
            <div className="navbar-item-label-top-xx">AUTORIZADOS</div>
          </div>
        </div>
        <div
          className={`${
            activeWorkSpace.todos
              ? "navbar-container-reqList-active"
              : "navbar-container-reqList"
          } ${disabledElements ? "navbar-container-disabled" : ""}`}
          onClick={() => (!disabledElements ? handleWorkSpace("todos") : null)}
        >
          <div className="navbar-item-reqList">
            <div className="navbar-item-label-top-xx">TODOS</div>
          </div>
        </div>
      </div>
      <div className="container-requests-list">
        <div className="opcionesHijasDiv">
        <button
            className={
              comprobanteSeleccionado === "NPI"
                ? `opcionHijaBtn selected`
                : "opcionHijaBtn"
            }
            disabled={disabledElements}
            onClick={() => handleChangeComprobante("NPI")}
          >
            NPI
          </button>
          <button
            className={
              comprobanteSeleccionado === "OC"
                ? `opcionHijaBtn selected`
                : "opcionHijaBtn"
            }
            disabled={disabledElements}
            onClick={() => handleChangeComprobante("OC")}
          >
            OC
          </button>
          <button
            className={
              comprobanteSeleccionado === "POP"
                ? `opcionHijaBtn selected`
                : "opcionHijaBtn"
            }
            disabled={disabledElements}
            onClick={() => handleChangeComprobante("POP")}
          >
            POP
          </button>
        </div>

        <FilterScreen
          verFiltro={handlerClickFilter}
          disabled={disabledElements}
        />
        <SearchInput defaultValue={filter} buscar={handdlerSearchInput} />

        <FixedSizeList
          height={height - 300}
          style={{ flexGrow: 1 }}
          itemCount={
            filter === ""
              ? listTDoc.length
              : listTDoc.filter(
                  (r) =>
                    r.title.toLowerCase().includes(filter.toLowerCase()) ||
                    (
                      r.tDocCodFor.toLowerCase() +
                      " - " +
                      r.tDocNroFor.toString().toLowerCase()
                    ).includes(filter.toLowerCase()) ||
                    r.tDocNroFor
                      .toString()
                      .toLowerCase()
                      .includes(filter.toLowerCase())
                ).length
          }
          itemSize={itemSize}
          width={"100%"}
        >
          {({ index, style }) => (
            <RenderRow
              currentUser={currentUser}
              index={index}
              style={style}
              listTDoc={listTDoc}
              filter={filter}
              checkPermisos={checkPermisos}
              cambiarEstado={handlerState}
              editar={handlerEdit}
              verDetalles={handlerDetails}
              statesNEviables={stateNoEnviables}
              sendToAPI={sendToAPI}
              guardarEstado={(r, state, observAut) =>
                modificarEstado(r, { state: state, observAut: observAut })
              }
              cerrar={handlerState}
              disabledElements={disabledElements}
            />
          )}
        </FixedSizeList>
      </div>
      {selectedFilter && (
        <div className="modalReq">
          <AddFilter
            usuarios={userListView}
            sectoresRequerimientos={reqsSectorsList}
            solicitantes={applicantsList}
            responsables={responsiblesList}
            item={selectedFilter}
            cerrar={handlerClickFilter}
            filtro={filtros}
            aplicarFiltros={aplicarFiltros}
            setFiltrosModificados={setFiltrosModificados}
            workspace={activeWorkSpace}
            comprobanteSeleccionado={comprobanteSeleccionado}
          />
        </div>
      )}
      {selected && (
        <div className="modalReq">
          <ReqInfo
            item={selected.item}
            files={selected.files}
            comprobantePdf={selected.comprobantePdf}
            cerrar={handlerDetails}
            verDetalle={verDetalle}
            repetir={repeat}
            generaPDF={generaPDF}
            abrirURL={handlerAbrirURL}
            guardarModificaciones={modificarEstado}
            usuario={currentUser}
          />
        </div>
      )}
      {selectedRepeat && (
        <div className="modalReq">
          <FormReq
            edicion={"Guardar_Como"}
            req={selectedRepeat.item}
            reqSectorForTitleEdicion={selectedRepeat.newReqSectorForTitle}
            itemList={selectedRepeat.nuevaListaP}
            guardarReq={guardarComo}
            cerrar={handlerRepeat}
            verDetalle={verDetalle}
          />
        </div>
      )}
      {selectedEdit && (
        <div className="modalReq">
          <FormReq
            edicion={"Edicion"}
            req={selectedEdit.req}
            reqSectorForTitleEdicion={selectedEdit.newReqSectorForTitle}
            itemList={selectedEdit.nuevaListaP}
            files={selectedEdit.files}
            guardarReq={editar}
            cerrar={handlerEdit}
            verDetalle={verDetalle}
          />
        </div>
      )}
      {selectedState && (
        <div className="modalReq">
          <StateChange
            item={selectedState}
            verDetalle={verDetalle}
            guardarEstado={(r, state, observAut) =>
              modificarEstado(r, { state: state, observAut: observAut })
            }
            cerrar={handlerState}
          />
        </div>
      )}
    </div>
  );
}

export default ReqList;