//Componentes generales
import { useEffect, useState } from 'react';
import clienteAxios from '../../config/axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';

//Estilos
import './FormUser.css';

// Alertas
import Swal from 'sweetalert2'

/*
    Componente encargado de mostrar el formulario de usuario.
        Donde data es el usuario crear/modificar.
              minimizar es la función encargada de ocultar el dialogo.
              generar es la función del context que guarda el usuario.
              titulo es la que determina que texto mostrar en encabezado del dialogo.
              verficaUser es la función del context que comprueba la licencia por usuario vigente del cliente.
*/
function FormUser({ data = undefined, minimizar, generar, titulo, verificaUser, listGroups, uSession }) {

    //State de usuario. Si no viene data por parametro significa que es nuevo por lo que se le asigna una estructura.
    const [user, setUser] = useState((data !== undefined) ? data : {
        userName: '',
        fullName: '',
        password: '',
        email: '',
        enabled: (false),
        userType: [{ _id: '', typeName: '', descr: '' }],
        gruposV: []
    });
    //State para errores. Valida que los datos ingresados por el usuario sean correctos.
    const [error, setError] = useState({
        userName: false,
        fullName: false,
        email: false,
        password: false,
        userType: false,
        msg: ''
    });

    //Constante que define el titulo que corresponde si vienen datos por parametros
    const tituloBoton = data === undefined ? 'Agregar' : 'Modificar';

    //State para items. En este contexto, llamamos items a los roles que puede poseer un usuario.
    const [items, setItems] = useState([]);
    //State para grupos. En este contexto, llamamos grupos a los grupos que puede poseer un usuario.
    const [groups, setGroups] = useState([]);
    //State que determina si el usuario tiene licencias.
    const [controlUsuarios, setControlUsuarios] = useState(false);
    //State que determina el rol del usuario seleccionado.
    const [selectedOptions, setSelectedOptions] = useState(user.userType.length > 0 && user.userType[0]._id !== '' ? user.userType.map((item) => ({value: item.typeName, label: item.descr})) : []);
    //State que determina los grupos que visualiza el usuario seleccionado.
    const [selectedGroupOptions, setGroupSelectedOptions] = useState([]); //useState(user.gruposV ? user.gruposV.map((item) => ({value: item.name, label: item.name})) : []);
      

    /* 
        Al renderizar el componente, setea los roles disponibles.
                                     setea la posibilidad de habilitar el usuario segun las licencias que posee. Si tiene usadas todas sus licencias, permite crear el usuario mas no permite habilitarlo.
    */
    useEffect(() => {

        //Actualiza la Lista de roles disponibles.
        const actualizar = async () => {
            const resp = await clienteAxios.get(`/userType`);
            let items = new Array();

            resp.data.data.map((type) => {
                items.push({ _id: type._id, typeName: type.typeName, descr: type.descr })
            });

            if(user.userName !== ''){

                const resp2 = await clienteAxios.get(`/grupoUsuarios/complete?guUserID=${user._id}`);
                let items2 = new Array();
                
                resp2.data.data.map((type) => {
                    items2.push({ _id: type.grupo._id, name: type.grupo.name })
                });

                //Recorrer groupV recuperar de cada grupo en ListGroup el objeto completo y luego crear el arreglo options
                if(user.gruposV && listGroups && listGroups.length > 0){
                    const listGroupsUser = user.gruposV.map((idGroup) => {
                        const group = listGroups.find((lg) => lg._id === idGroup);
                        return group;
                    }); 
        

                    const items3 = listGroupsUser.map((g) => ({ _id: g._id, name: g.name }))

            

                    //Setea Visualiza de
                    setGroupSelectedOptions(items3);
                }

                setGroups(items2);
            }
            setItems(items);



        }
        actualizar();

        //Recupera usuarios habilitados y seteo state de control.
        const verificar = async () => {
            const licenciaUsuario = await verificaUser();
            if(user.enabled){
                setControlUsuarios(false);
            }else{
                if(licenciaUsuario.data.userEnabled === licenciaUsuario.data.maxUsersEnabled){
                    setControlUsuarios(true);
                }else{
                    setControlUsuarios(false);                    
                }
            }
        }
        verificar();

    }, []);
    

    /*
        Función que muestra un pop it alerta de tipo advertencia.
            Donde mensaje es lo que queremos que diga la alerta.
    */
    const alerta = (mensaje) => {
        Swal.fire({
            text: mensaje, 
            icon: 'warning',
            confirmButtonText: 'OK'
        })
    }

    /* 
        Disparador ante cambios en el state de error. Si alguno de sus atributos es true, muestra alerta.
    */
    useEffect(() => {
        if (error.userName || error.fullName || error.email || error.password)
            alerta(error.msg);

    }, [error])

    /* 
        Manejador de selección de roles de usuario.
            Donde selected son todos los roles seleccionado. 
    */
    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
        const filteredArray = items.filter(obj => selected.some(sel => obj.typeName === sel.value));
        setUser({ ...user, userType: filteredArray});
    };

    /* 
        Manejador de selección de grupos a visualizar del usuario.
            Donde selected son todos los grupos seleccionado. 
    */
    const handleSelectGroupChange = (selected) => {
        console.log(selected)
        setGroupSelectedOptions(selected.map((i) => ({ _id: i.value, name: i.label })));
        const filteredArray = selected.map((i) => i.value);
        setUser({ ...user, gruposV: filteredArray});
    };

    /*
    const handleRolesUsuario = (rol,check) =>{
        if(check){
            const nuevoArregloRoles = [...user.userType.filter((d) => d._id !== ''), rol];
            setUser({ ...user, userType: nuevoArregloRoles});
        }else{
            const nuevoArregloRoles = [...user.userType.filter((d) => d._id !== rol._id)];
            setUser({ ...user, userType: nuevoArregloRoles});
        }
    }*/

    /*
        Guarda el usuario mediante función del context.
        Antes de llamar a la función, verifica que pase todas las validaciones.
    */
    const handlerGenerar = async () => {

        const verificar = async () => {
            let isGenerable = true;
            let errors = {
                userName: false,
                fullName: false,
                email: false,
                password: false,
                userType: [],
                msg: ''
            };

            //Verificaciones
            if (data === undefined) {
                if (user.userName === '') {

                    errors.userName = true
                    errors.msg = 'No ingreso un nombre de usuario';

                } else {
                    try {
                        const resp = await clienteAxios.get(`/user/userName/${user.userName}`);
                        if(resp.data.data !== null) {
                            errors.userName = true;
                            errors.msg = 'Ingreso un nombre de usuario ya existente';
                        }    
                    } catch (error) {
                        
                    }

                };
            }
            if (user.fullName === '') {
                errors.fullName = true;
                errors.msg = 'No ingreso un nombre';
            }

            if (user.email === '') {
                errors.email = true;
                errors.msg = 'No ingreso un email';
            }
            if (user.password === '' && data === undefined) {
                errors.password = true;
                errors.msg = 'No ingreso una contraseña';
            }
            if (user.userType.length < 1) {
                errors.userType = true;
                errors.msg = 'Tipo de usuario no valido';
            }

            setError(errors);

            if (errors.userName === true || errors.fullName === true || errors.email === true || errors.password === true) isGenerable = false;

            return isGenerable;
        };

        const resp = await verificar();

        if (resp === true) generar(user, (data === undefined) ? 0 : 1);

    }




    return (
        <div className='container-gr-form'>
            <div className='title-form'>
                {titulo}
            </div>
            <div className='container-form'>
                <div className='label-form'>Usuario: </div>
                <div><input defaultValue={user.userName} className='label-form-data' disabled={(data !== undefined) ? true : false} onChange={data => setUser({ ...user, userName: data.target.value })}></input></div>
            </div>
            <div className='container-form'>
                <div className='label-form'>Nombre: </div>
                <div><input defaultValue={user.fullName} className='label-form-data' onChange={data => setUser({ ...user, fullName: data.target.value })} ></input></div>
            </div>
            <div className='container-form'>
                <div className='label-form'>Email: </div>
                <div><input defaultValue={user.email} className='label-form-data' onChange={data => setUser({ ...user, email: data.target.value })}></input></div>
            </div>
            <div className='container-form'>
                <div className='label-form'>Nueva Clave: </div>
                <div><input type="password" defaultValue={user.password} className='label-form-data' onChange={data => setUser({ ...user, password: data.target.value })}></input></div>
            </div>
            <div className='container-form'>
                <div className='label-form'>Habilitado: </div>
                { !controlUsuarios &&
                    <div><input type="checkbox" defaultChecked={user.enabled} className='label-form-check-data' onChange={data => setUser({ ...user, enabled: data.target.checked })}></input></div>
                }
                { controlUsuarios &&
                    <div><input type="checkbox" className='label-form-check-data' disabled ></input></div>
                }

            </div>

            <div className='container-form'>
                <div className='label-form'>Rol: </div>
                <div className='container-checkbox'>      
                <Select
                    styles={{
                        multiValueLabel: (base) => ({
                        ...base,
                        backgroundColor: "#00D4E2",
                        color: 'white',
                        borderRadius: "none",
                        fontSize: "1rem"
                        }), 
                        multiValueRemove: (base) => ({
                            ...base,
                            backgroundColor: "#00D4E2",
                            color: 'white',
                            borderRadius: "none",
                            fontSize: "1rem"
                        }),
                    }}
                    isMulti
                    placeholder=""
                    options={items && items.length > 0 ? items.map(function(item) {
                        return { value: item.typeName, label: item.descr };
                      }) : []}
                    value={selectedOptions}
                    onChange={handleSelectChange}
                />
                </div>
            </div>
            <div className='container-form'>
                <div className='label-form'>Visualiza de: </div>
                <div className='container-checkbox'>      
                <Select
                    styles={{
                        multiValueLabel: (base) => ({
                        ...base,
                        backgroundColor: "#00D4E2",
                        color: 'white',
                        borderRadius: "none",
                        fontSize: "1rem"
                        }), 
                        multiValueRemove: (base) => ({
                            ...base,
                            backgroundColor: "#00D4E2",
                            color: 'white',
                            borderRadius: "none",
                            fontSize: "1rem"
                        }),
                    }}
                    isMulti
                    options={listGroups && listGroups.length > 0 && listGroups.map(function(item) {
      
                                            return {value: item._id, label: item.name};
                                        })}
                    placeholder=""
                    value={selectedGroupOptions && selectedGroupOptions.length > 0 && selectedGroupOptions.map(function(item) {
                      
                        return {value: item._id, label: item.name};
                    })}
                    
                    
                    onChange={handleSelectGroupChange}
                />
                </div>
            </div>
            <div className='container-form'>
                <div className='label-form'>Pertenece a: </div>
                <div className='container-list-Wgroups'>  
                    <Select
                        styles={{
                            multiValueLabel: (base) => ({
                            ...base,
                            backgroundColor: "#00D4E2",
                            color: 'white',
                            borderRadius: "none",
                            fontSize: "1rem"
                            }), 
                            multiValueRemove: (base) => ({
                                ...base,
                                display: 'none'
                            }),
                            dropdownIndicator: (base) => ({
                                ...base,
                                display: 'none'
                            }),
                            indicatorsContainer: (base) => ({
                                ...base,
                                display: 'none'
                            }),
                        }}
                        isMulti
                        menuIsOpen={false}
                        isSearchable={false} 
                        placeholder=""
                        value={groups && groups.length > 0 && groups.map(function(item) {
                                            return {value: item._id, label: item.name};
                                        })}
                        disabled
                    />
                </div>
            </div>

            <div className='container-btn'>
                <button className='btn-form' onClick={handlerGenerar}>{tituloBoton}</button>
            </div>
            <div className='container-btn'>
                <button className='btn-cancel-form' onClick={minimizar}>Cancelar</button>
            </div>
        </div>
    );
}

export default FormUser