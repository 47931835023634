//Componentes generales
import { useEffect, useContext } from "react";
import UserList from "../../components/UserList/UserList";

// Alertas
import Swal from "sweetalert2";

//Contexto para manipulacion de usuario y validacion de inicio de sesion
import usuariosContext from "../../context/usuarios/usuariosContext";
import authContext from "../../context/authentication/authContex";
import gruposContext from "../../context/grupos/gruposContext";

/* 
    Vista donde se gestiona toda la lógica del ABM de usuarios + listado.
        Donde props no se encuentra en uso.
*/
function User(props) {
  //Se Obtiene el usuario en sesion
  const aContext = useContext(authContext);
  const { linksApp, usuario } = aContext;

  //Context
  const uContext = useContext(usuariosContext);
  const {
    userList,
    verModificar,
    userSeleccionado,
    error,
    limpiarError,
    actualizarUserList,
    verFormModificar,
    seleccionarUser,
    modificarUser,
    agregarUser,
    eliminarUser,
    verificarLicencia,
  } = uContext;

  const gContext = useContext(gruposContext);
  const { gruposList, actualizarGroupList } = gContext;

  /* 
        Disparador que se ejecuta ante la renderización del componente. El mismo se encarga de actualizar la lista de usuarios disponibles.
    */
  useEffect(() => {
    actualizarUserList();
  }, []);

  /* 
        Disparador que se ejecuta ante la renderización del componente. El mismo se encarga de actualizar la lista de grupos disponibles.
    */
  useEffect(() => {
    if (!gruposList || gruposList.length == 0) actualizarGroupList();
  }, []);

  /* 
        Disparador que se ejecuta ante cambios en la variable error proveniente del context. El mismo muestra una alerta popIt del error seteado en el contexto.
    */
  useEffect(() => {
    if (error !== null && error !== "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
        timer: 5000,
        customClass: {
          container: "swal-container",
        },
        allowEnterKey: true,
      }).then(() => {
        limpiarError();
      });
    }
  }, [error]);

  /* 
        Manejador encargado de gestionar la vista del formulario de modificación. Se setea el usuario seleccionado de la lista.
            Donde user es el usuario seleccionado de la lista.
    */
  const handlerVerFormModificar = async (user) => {
    await seleccionarUser(user);
    await verFormModificar();
  };

  /* 
         Manejador encargado de gestionar la vista del detalle de un usuario especifico. Se setea el usuario seleccionado de la lista.
            Donde user es el usuario seleccionado de la lista.   
    */
  const handlerVerUser = async (user) => {
    await seleccionarUser(user);
  };

  /* 
        Manejador encargado de gestionar la modificación y/o el agregado de un usuario. Segun mode dado por parametros es la llamada al context que realiza.
            Donde user es el usuario modificado/nuevo.
                  mode es el que indica que función del context ejecutar (0 = Nuevo Usuario; 1 = Modificación de usuario).
    */
  const handlerModAddUser = async (user = undefined, mode = 0) => {
    if (user !== undefined && user !== null) {
      if (mode === 0) {
        await agregarUser(user, usuario);
        actualizarUserList();
        actualizarGroupList();
        Swal.fire({
          title: `Se creo un Nuevo Usuario`,
          icon: "success",
          text: `El nombre de Usuario es : ${user.userName}`,
          showConfirmButton: true,
          allowEnterKey: true,
        });
      } else if (mode === 1) {
        await modificarUser(user);
        actualizarUserList();
        Swal.fire({
          title: `Se Modifico el Usuario ${user.userName}`,
          icon: "success",
          showConfirmButton: true,
          allowEnterKey: true,
          customClass: {
            container: "swal-container",
          },
        });
      }
    }
  };

  /* 
        Manejador encargado de gestionar la eliminación de un usuario. El context realiza sus propias validaciones internas. Actualiza la userList inmediatamente.
            Donde user es el usuario a eliminar.
    */
  const handlerEliminarUser = async (user) => {
    Swal.fire({
      title: "Estas seguro que deseas eliminar este Usuario?",
      text: "Se eliminara permanentemente!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Borrar",
      cancelButtonText: "No, cancelar",
      reverseButtons: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await eliminarUser(user);
          await actualizarUserList();

          Swal.fire(
            "Borrado!",
            "El Usuario Fue Borrado Correctamente.",
            "success"
          );
        } catch (error) {
          console.error("Error al eliminar el usuario:", error);
          let errorMessage =
            error.response?.data?.message || "No se pudo eliminar el usuario.";
          Swal.fire("Error!", errorMessage, "error");
        }
      } else {
        if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            "Cancelado",
            "El Usuario no fue Borrado del Sistema",
            "error"
          );
        } else {
          Swal.fire("Error!", "El Usuario no puede ser Borrado.", "error");
        }
      }
    });
  };

  return (
    <div className="container-list">
      <div className="container-user-back">
        <UserList
          list={userList}
          verModificar={handlerVerFormModificar}
          estadoVerModificar={verModificar}
          saveModificacion={handlerModAddUser}
          verUser={handlerVerUser}
          seleccionado={userSeleccionado}
          eliminarUser={handlerEliminarUser}
          verificaCantidadUsers={verificarLicencia}
          listGroups={gruposList}
          linksApp= {linksApp}
        />
      </div>
    </div>
  );
}

export default User;