export const STATES = [
    "PENDIENTE",
    "PENDIENTE (REVISIÓN MOSCUZZA)",
    "RECHAZADO",
    "AUTORIZADO"
];

export const ifState = (state, tDocCodFor) => {
    if(state== "PENDIENTE" && (tDocCodFor == "NPI" || tDocCodFor == "REQ" || tDocCodFor == "NPIP" || tDocCodFor == "POP")){
        return ["AUTORIZADO", "RECHAZADO"];
    }else if(state== "RECHAZADO"){
        return [];
    }else if(state== "AUTORIZADO"){
        return [];
    }else if(state== "PENDIENTE" && (tDocCodFor == "OC" || tDocCodFor == "OCP")){
        return ["PENDIENTE (REVISIÓN MOSCUZZA)", "AUTORIZADO", "RECHAZADO"];
    }else if(state== "PENDIENTE (REVISIÓN MOSCUZZA)" && (tDocCodFor == "OC" || tDocCodFor == "OCP")){
        return ["PENDIENTE", "AUTORIZADO", "RECHAZADO"];
    }
    return [];
}