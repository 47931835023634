import clienteAxios from "../config/axios";

export async function addTransactionDocument(tDoc, productList) {
  return clienteAxios.post(`/transactionDocument`, { transactionDocument: tDoc, products: productList });
}

export async function addFiles(formData) {
  console.log(formData);
  return clienteAxios.post("/transactionDocument/file", formData, { headers: { "Content-Type": "multipart/form-data" }, timeout: 0 });
}

export async function updateTDoc(tDoc, updates, user) {
  return clienteAxios.put("/transactionDocument", { _id: tDoc._id, updates, fecMod: tDoc.fecMod, user });
}

export async function deleteFile(fileName) {
  return clienteAxios.delete(`/transactionDocument/file/${fileName}`);
}

export async function getTDocByFilter(filtros) {
  return clienteAxios.get("/transactionDocument/filter", filtros);
}

export async function createPDF(tDocCodFor ,tDocNroFor, userId) {
  return clienteAxios.post("/transactionDocument/informe", { tDocCodFor, tDocNroFor, userId });
}

export async function getFiles(tDocCodFor, tDocNroFor, type) {
  return clienteAxios.get(`/transactionDocument/files/${tDocCodFor}/${tDocNroFor}/${type}`);
}

export async function getFile(fileName) {
  return clienteAxios.get(`/transactionDocument/file/${fileName}`);
}

export async function sendToWebService(_id, user){
  return clienteAxios.post("/transactionDocument/sendToWebService", { _id, user });
}

export async function getTDocsByUserIdAndParams(userId, filtros){
  return clienteAxios.get(`/transactionDocument/${userId}${filtros}`);
}

export async function getTransactionDocumentsByTDocCodForAndParams(tDocCodFor, filtros){
  return clienteAxios.get(`/transactionDocument/tDocCodFor/${tDocCodFor}${filtros}`);
}
